import React, { StrictMode } from "react";

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import dayjs from 'dayjs';
import { createRoot } from "react-dom/client";

import "./styles.css";

dayjs.locale('zh-cn');

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);