import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider, Typography, Carousel, Image, Layout, Flex } from 'antd';
const { Title, Paragraph, Text, Link } = Typography;
const { Header, Footer, Content } = Layout;
const headerStyle = {
  height: 64,
  fontSize: '25px',
  paddingInline: 48,
  backgroundColor: '#ffffff',
};
const contentStyle = {
  paddingInline:30,
  minHeight: 150,
  lineHeight: '120px',
};
const footerStyle = {
  textAlign: 'center',
  margin: 0
};
const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  width: 'calc(100%)',
  maxWidth: 'calc(100%)',
};
const carouselStyle = {
  height: '360px',
  color: '#fff',
  textAlign: 'center',
};

const footLogoStyle = {
  width: '16px',
};

const gawbStyle = {
  margin: 10
};


export default () => {


  return (
    <ConfigProvider locale={zhCN}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
        <b>Archi智慧建筑	</b>
        </Header>
        <Content style={contentStyle}>
          <Typography>
          <Title>产品介绍</Title>
          <Paragraph>
              Archi智慧建筑	是一款基于人工智能技术的建筑设计辅助工具,旨在帮助建筑设计师们更高效地进行建筑设计工作。通过使用大模型进行建筑设计,Archi智慧建筑	能够生成通用模型,并提供多种设计方案供设计师选择。
            </Paragraph>
            <Carousel autoplay>
            <div>
              <h3 style={carouselStyle}><Image src="https://archi-1317440414.cos.ap-shanghai.myqcloud.com/home%2FBanner0222.gif"></Image></h3>
            </div>
            <div>
              <h3 style={carouselStyle}><Image src="https://archi-1317440414.cos.ap-shanghai.myqcloud.com/home%2FBanner5454.gif"></Image></h3>
            </div>
          </Carousel>
          <Title level={2}>产品功能</Title>
            <Paragraph>
              基于人工智能大模型的建筑设计:Archi智慧建筑	使用先进的人工智能大模型技术,对建筑设计师的需求进行分析和理解,从而生成符合设计师需求的设计方案。
            </Paragraph>
            <Paragraph>
              生成通用模型:Archi智慧建筑	能够根据不同的设计需求,生成相应的通用模型,提高设计师的工作效率。
            </Paragraph>
            <Paragraph>
            Archi智慧建筑	利用人工智能技术进行建筑设计,可以更好地理解客户需求,实现个性化设计。同时,它还可以通过优化设计流程,提高设计质量,降低设计成本。
            </Paragraph>
            <Paragraph>
            Archi智慧建筑	利用人工智能技术进行建筑设计,可以减少人为错误,提高设计准确性。
            </Paragraph>
            <Paragraph>
            Archi智慧建筑	可以帮助建筑设计师更好地实现可持续发展,例如通过优化设计方案,减少材料和能源的消耗,降低环境污染。
            </Paragraph>
            <Paragraph>
            Archi智慧建筑	能够生成多种设计方案供设计师选择,这为设计师提供了更多的灵感和创新空间,可以帮助他们更好地满足客户需求。
            </Paragraph>
            <Title level={2}>免责声明</Title>
            <Paragraph>
            特别说明：本公司产品智慧助理小E，针对所有企业提供无限试用服务，无特殊情况，不收取任何费用。
            </Paragraph>
          </Typography>

        </Content>
        <Footer style={footerStyle}> <div>ArchiAI ©{new Date().getFullYear()} Created by 轻至建筑设计咨询（上海）有限公司</div>
        <img src="assets/logo01.png" style={footLogoStyle}/>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011202020168" target="_blank" style={gawbStyle}>沪公网安备31011202020168</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >沪ICP备2024051735号-1</a>
        </Footer>
      </Layout>

    </ConfigProvider>
  );
}
